import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import FAQs from "../components/Repeating/FAQs";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedLoanPrograms from "../components/Repeating/RelatedLoanPrograms";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import noDownPayment from "../images/2.0 Loan Programs/VA/No Down Payment.svg";
import noPrivateMortageInsurance from "../images/2.0 Loan Programs/VA/No Private Mortage Insurance.svg";
import competitiveInterestRates from "../images/2.0 Loan Programs/VA/Competitive Interest Rates.svg";
import relaxedCreditRequirements from "../images/2.0 Loan Programs/VA/Relaxed Credit Requirements.svg";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "What housing markets do you serve?",
      answer: (
        <>
          <p className="mb-0">
            We serve eight states, with areas as diverse as{" "}
            <Link
              to="/tempe-va-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Tempe
            </Link>
            , Arizona,{" "}
            <Link
              to="/las-vegas-va-loans/"
              className="font-normal text-typography-body no-underline"
            >
              Las Vegas
            </Link>
            , Nevada, and{" "}
            <Link
              to="/san-bernardino-va-loans/"
              className="font-normal text-typography-body no-underline"
            >
              San Bernardino
            </Link>
            , California.
          </p>
        </>
      ),
    },
    {
      question: "What documents will I need to provide?",
      answer: (
        <>
          <p>
            You’ll need to first obtain a Certificate of Eligibility (COE) from
            the Department of Veterans Affairs, which confirms for your lender
            that you qualify for a VA loan benefit. First, ask your lender if
            they can obtain the COE for you. Many lenders have access to a
            system that allows them to obtain it quickly. If your lender is
            unable to assist, you can also request a COE either online or by
            mail. You’ll find instructions on the{" "}
            <a
              href="https://www.va.gov/housing-assistance/home-loans/how-to-request-coe/"
              target="_blank"
              rel="noreferrer"
            >
              Department of Veterans Affairs website
            </a>
            .
          </p>
          <p>
            If you are on active duty, you will need to obtain a State of
            Service signed by your unit commander or higher headquarters. The
            document must identify you and your social security number, show
            your date of entry, and reflect any time lost.
          </p>
          <p>
            If you are a veteran, you will need to provide a copy of your DD
            Form 214 that identifies your condition of discharge.
          </p>
          <p className="mb-0">
            For more details on the required proof of military service, you can
            reference the FAQs that the U.S. Department of Veterans Affairs{" "}
            <a
              href="https://www.benefits.va.gov/homeloans/faq_eligibility.asp"
              target="_blank"
              rel="noreferrer"
            >
              provides online
            </a>
            .
          </p>
        </>
      ),
    },
    {
      question: "What types of property can I buy with a VA loan?",
      answer: (
        <>
          <p>
            You can use a VA loan to purchase the same types of property that
            are eligible under other types of loans. That includes:
          </p>
          <ul className="styled-list-checkmark">
            <li>Single-family homes</li>
            <li>Condominiums (must be located within a VA-approved complex)</li>
            <li>Manufactured homes (must be attached to land that you own)</li>
            <li>
              Modular or prefabricated homes (must be attached to a permanent
              foundation)
            </li>
            <li>
              New construction (builders, plans, and sites must be VA-approved)
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I use my Basic Allowance for Housing (BAH) to qualify?",
      answer: (
        <>
          <p className="mb-0">
            Yes, your BAH is considered a verifiable and reliable income. It's
            factored into your debt-to-income (DTI) ratio, which is a key factor
            that helps determine if you qualify for a VA home loan.
          </p>
        </>
      ),
    },
    {
      question: "Can I get a VA loan more than once?",
      answer: (
        <>
          <p className="mb-0">
            Yes, you can! VA loans are not a one-time benefit—they are designed
            to be used throughout your lifetime, and there is no limit on how
            many times you can be eligible. In some cases, you can even have
            multiple VA loans simultaneously (for example, if you decide to turn
            your home into a rental and buy a new primary home for yourself with
            your remaining entitlement).
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="VA Home Loans | Right Start | Mortgage Lender"
        description="Military members and veterans— learn how to buy a home with no money down and no mortgage insurance with a VA home loan. Call to request rates."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/2.0 Loan Programs/VA/1.0 Va-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>VA Home Loan</h1>
              <p>
                VA loans are backed by the U.S. Department of Veterans Affairs
                and come with great benefits, such as no money down and
                competitive interest rates. You also don’t have to have perfect
                credit, or even near-perfect credit to apply.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>Your Guide to VA Home Loans</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Who it’s For"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Benefits"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Eligibility"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Who It’s For</h3>
                <p className="mb-0">
                  Active military service members, honorably discharged
                  veterans, National Guard and Reserve members, and spouses of
                  fallen service members can get a VA loan if they meet the
                  eligibility requirements.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-10 md:mb-12">
                  <h3 className="heading-four">The Benefits</h3>
                </header>

                <div className="grid gap-8 md:grid-cols-2 md:gap-10">
                  <div>
                    <img
                      src={noDownPayment}
                      alt="No Down Payment"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">No Down Payment</h3>
                    <p className="mb-0">
                      This is a huge advantage for veterans and service members
                      and the single-largest benefit of the VA loan.
                    </p>
                  </div>

                  <div>
                    <img
                      src={noPrivateMortageInsurance}
                      alt="No Private Mortgage Insurance"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">
                      No Private Mortgage Insurance
                    </h3>
                    <p className="mb-0">
                      VA loans do not require that you pay private mortgage
                      insurance (PMI), which other loans do.
                    </p>
                  </div>

                  <div>
                    <img
                      alt="Competitive Interest Rates"
                      className="mb-2.5"
                      src={competitiveInterestRates}
                    />
                    <h3 className="heading-six">Competitive Interest Rates</h3>
                    <p className="mb-0">
                      VA loans have some of the lowest interest rates on the
                      market, which can save you thousands.
                    </p>
                  </div>

                  <div>
                    <img
                      src={relaxedCreditRequirements}
                      alt="Relaxed Credit Requirements"
                      className="mb-2.5"
                    />
                    <h3 className="heading-six">Relaxed Credit Requirements</h3>
                    <p className="mb-0">
                      Credit scores are typically lower for VA loans than what
                      you would need for a conventional mortgage.
                    </p>
                  </div>
                </div>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Eligibility Requirements</h3>
                <ul className="styled-list-checkmark">
                  <li>
                    Active Duty Military: 90 consecutive days of service during
                    wartime or 181 days of service during peacetime
                  </li>
                  <li>National Guard & Reserves: 6 years of service</li>
                  <li>
                    Spouses: Married to a service member who died in the line of
                    duty or as a result of a service-related disability
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQs heading="FAQs" uniqueFaqs={faqs} />
      <Testimonials />
      <ValueProps />
      <About />
      <RelatedLoanPrograms hiddenProgram={2} />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/VA Home Loans.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/VA Home Loans.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
